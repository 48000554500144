<template>
    <div>
        <div class="item-image-box">
            <img class="back-arrow" src="/images/Exchange/Arrowhead_Left.png" @click="exit"/>
            <img class="item-image" :src="info.poster && info.poster.length > 0 ? info.poster : '/images/Exchange/item_example.png'" />
            <div class="price-box">
                <div style="font-size: 15px; margin-bottom: 5px;">贡献值</div>
                <div style="font-size: 18px; font-weight: bold;">{{ info.price }}</div>
            </div>
        </div>
        <div class="item-basic-info-box">
            <div class="remain-num-box">
                <van-icon name="bag-o" />
                剩余 {{ info.remainNumber }} 件
            </div>
            <div class="item-name">
                {{ info.name }}
            </div>
        </div>
        <div class="item-description-box">
            <div class="description">
                <div class="description-title">商品简介</div>
                <div class="description-item" v-for="(item, index) in info.commodityText" :key="index">
                    {{index + 1 + "、" + item }}
                </div>
            </div>
            <div class="description" style="margin-top: 10px;">
                <div class="description-title">兑换须知</div>
                <div class="description-item" v-for="(item, index) in info.notice" :key="index">
                    {{index + 1 + "、" + item }}
                </div>
            </div>
        </div>
        <div class="exchange-box">
            <div class="exchange-btn" @click="openOverlay">
                立即兑换
            </div>
        </div>
        <van-overlay :show="overlayShow">
            <div class="exchange-detail-overlay" @click.stop>
                <div class="exchange-detail-box">
                    <img class="close-icon" src="/images/Exchange/Close.png" @click="overlayShow = false" />
                    <div class="exchange-detail-banner">
                        <img class="exchange-detail-banner-image" src="/images/Exchange/detail_banner.png" />
                    </div>
                    <div class="exchange-detail-title">
                        {{ info.name }}
                    </div>
                    <div class="exchange-price-box">
                        <div class="exchange-price">
                            {{ info.price }}贡献值
                        </div>
                        <div class="exchange-remain">
                            剩余 {{ info.remainNumber }} 件
                        </div>
                    </div>
                    <div class="select-item">
                        <div class="select-item-title">规格：</div>
                        <div class="select-item-val-box" @click="openSpec">
                            <div class="select-item-val"> {{ selectedSpec == "" ? "请选择规格" :  selectedSpec }}</div>
                            <img class="icon-arrow" src="/images/Exchange/Arrowhead_Right.png" />
                        </div>
                    </div>
                    <div class="select-item">
                        <div class="select-item-title">地址：</div>
                        <div class="select-item-val-box" @click="openAddr">
                            <div class="select-item-val">
                                <div class="select-item-val-addr">
                                    {{ selectedAddr == "" ? "请选择地址" :  addrsDetail[addrNameToId[selectedAddr]].province + addrsDetail[addrNameToId[selectedAddr]].city + addrsDetail[addrNameToId[selectedAddr]].region + addrsDetail[addrNameToId[selectedAddr]].detail}}
                                </div>
                                <div class="select-item-val-other">
                                    {{ selectedAddr == "" ? "" :  addrsDetail[addrNameToId[selectedAddr]].name + " " + addrsDetail[addrNameToId[selectedAddr]].phone }}
                                </div>
                            </div>
                            <img class="icon-arrow" src="/images/Exchange/Arrowhead_Right.png" />
                        </div>
                    </div>
                    <div class="select-item">
                        <div class="select-item-title">贡献值选项：</div>
                        <div class="select-item-checkbox">
                            <div v-for="(item, index) in projectList" :key="index">
                                <div class="checkbox-item">
                                    <div class="checkbox-item-name">
                                        {{ item.Title }}（余额：{{ item.TotalToNumber }}）
                                    </div>
                                    <img @click="selectProject(index)" class="icon-checkbox" :src="selectedProjectId == index ? '/images/Exchange/Checked.png' : '/images/Exchange/Unchecked.png'" />
                                </div>
                            </div>
                            <!-- <div class="checkbox-item">
                                <div class="checkbox-item-name">
                                    万人添X（余额：100）
                                </div>
                                <img class="icon-checkbox" src="/images/Exchange/Unchecked.png" />
                            </div> -->
                        </div>
                    </div>
                    <div class="submit-btn" @click="submitExchange">
                        确定
                    </div>
                </div>
            </div>
        </van-overlay>
        <van-overlay :show="successOverlayShow">
            <div class="exchange-detail-overlay">
                <div class="success-hint-box">
                    <img class="success-icon" src="/images/Exchange/Success_Add.png" />
                    <div class="success-hint-text">兑换成功</div>
                    <img class="close-icon" src="/images/Exchange/Close.png" @click="successOverlayShow = false" />
                </div>
            </div>
        </van-overlay>
        <van-popup v-model="specShow" position="bottom">
            <van-picker
                title="选择规格"
                show-toolbar
                :columns="specs"
                :loading="specLoading"
                @confirm="onSpecConfirm"
                @cancel="onSpecCancel"
                @change="onSpecChange"
            />
        </van-popup>
        <van-popup v-model="addrShow" position="bottom">
            <van-picker
                title="选择地址"
                show-toolbar
                :columns="addrs"
                :loading="addrLoading"
                @confirm="onAddrConfirm"
                @cancel="onAddrCancel"
                @change="onAddrChange"
            />
        </van-popup>
    </div>
</template>

<script>
    import { Icon, Overlay, Notify, Popup, Picker } from 'vant';
    import { getCommodityInfoById, getCommoditySpecsById, getUserAddr } from "@/api/Exchange";
    import { addExchangeRecord } from "@/api/ExchangeRecord";
    import { getProjectAvailValById } from "@/api/Home";
    export default {
        name: "Item",
        data() {
            return {
                info: {
                    remainNumber: 0,
                    price: 0,
                    name: "",
                    image: "",
                    commodityText: [],
                    notice: []
                },
                overlayShow: false,
                successOverlayShow: false,
                specShow: false,
                specLoading: true,
                specs: [],
                specNameToId: {},
                selectedSpec: "",

                addrShow: false,
                addrLoading: true,
                addrs: [],
                addrNameToId: {},
                selectedAddr: "",
                addrsDetail: [],

                projectList: [],
                selectedProjectId: -1
            }; 
        },
        components: {
            'van-icon': Icon,
            'van-overlay': Overlay,
            'van-popup': Popup,
            'van-picker': Picker
        },
        methods: {
          exit(){
            this.$router.go(-1)
          },
            openOverlay() {
                if (this.info.remainNumber == 0) {
                    Notify({type: "danger", message:'商品已兑换完'});
                    return;
                }
                this.overlayShow = true;
                this.getContributionValue();
            },
            async getContributionValue() {
                try {
                    // get contribution value
                    let info = this.$store.state.user.info;
                    let id = info.id;
                    let {errorCode, payload} = await getProjectAvailValById({id});
                    if (errorCode == 200) {
                        this.projectList = payload;
                        // this.contributionValue = payload[0].contributionValue;
                    } else {
                        Notify({type: "danger", message:'获取贡献值失败'});
                    }
                } catch (err) {
                    console.log(err);
                    Notify({type: "danger", message:'获取贡献值失败'});
                }
            },
            async submitExchange() {
                if (this.selectedSpec == "") {
                    Notify({type: "danger", message:'请选择规格'});
                    return;
                }
                if (this.selectedAddr == "") {
                    Notify({type: "danger", message:'请选择地址'});
                    return;
                }

                if (this.selectedProjectId == -1) {
                    Notify({type: "danger", message:'请选择贡献值选项'});
                    return;
                }
                
                try {
                    let userInfo = this.$store.state.user.info;
                    // get spec id and addr id
                    let specId = this.specNameToId[this.selectedSpec];
                    let addrId = this.addrsDetail[this.addrNameToId[this.selectedAddr]].id;
                    let data = {
                        userId: userInfo.id,
                        commodityId: this.$route.params.id,
                        price: this.info.price,
                        commoditySpecId: specId,
                        projectId: this.projectList[this.selectedProjectId].ObjectID,
                        userAddrId: addrId,
                        number: 1 // temporarily number is 1
                    };

                    let {errorCode} = await addExchangeRecord(data);
                    if (errorCode == 200) {
                        this.overlayShow = false;
                        this.successOverlayShow = true;
                        let id = this.$route.params.id;
                        this.getCommodityInfo(id);
                    } else {
                        Notify({type: "danger", message:'兑换失败'});
                    }
                } catch (err) {
                    console.log(err);
                    Notify({type: "danger", message:'兑换失败'});
                }
            },
            hideOverlay() {
                this.overlayShow = false;
            },
            async getCommodityInfo(id) {
                try {
                    // get commodity info
                    let {errorCode, payload} = await getCommodityInfoById(id);
                    if (errorCode == 200) {
                        if (payload.length == 0) {
                            Notify({type: "danger", message:'获取商品信息失败'});
                            return;
                        }
                        this.info = payload[0];
                    } else {
                        Notify({type: "danger", message:'获取商品信息失败'});
                    }
                } catch (err) {
                    console.log(err);
                    Notify({type: "danger", message:'获取商品信息失败'});
                }
            },
            onSpecConfirm(value) {
                this.selectedSpec = value;
                this.specShow = false;
            },
            onSpecCancel() {
                this.specShow = false;
            },
            onSpecChange() {
                this.specShow = false;
            },
            onAddrConfirm(value) {
                this.selectedAddr = value;
                this.addrShow = false;
            },
            onAddrCancel() {
                this.addrShow = false;
            },
            onAddrChange() {
                this.addrShow = false;
            },
            async openSpec() {
                this.specLoading = true;
                this.specShow = true;
                try {
                    // get commodity specs
                    let {errorCode, payload} = await getCommoditySpecsById(this.$route.params.id);
                    if (errorCode == 200) {
                        if (payload.length == 0) {
                            Notify({type: "danger", message:'获取商品规格失败'});
                            this.specLoading = false;
                            this.specShow = false;
                            return;
                        }
                        let specs = [];
                        let specNameToId = {};
                        for (let i = 0; i < payload.length; i++) {
                            specs.push(payload[i].specName);
                            specNameToId[payload[i].specName] = payload[i].id;
                        }
                        this.specs = specs;
                        this.specNameToId = specNameToId;
                        this.specLoading = false;
                    } else {
                        Notify({type: "danger", message:'获取商品规格失败'});
                        this.specLoading = false;
                        this.specShow = false;
                    }
                } catch (err) {
                    console.log(err);
                    Notify({type: "danger", message:'获取商品规格失败'});
                }
            },
            async openAddr () {
                this.addrLoading = true;
                this.addrShow = true;
                try {
                    // get addrs
                    let info = this.$store.state.user.info;
                    let {errorCode, payload} = await getUserAddr(info.id);
                    if (errorCode == 200) {
                        if (payload.length == 0) {
                            Notify({type: "danger", message:'获取用户地址失败'});
                            this.addrLoading = false;
                            this.addrShow = false;
                            return;
                        }
                        let addrs = [];
                        let addrNameToId = {};
                        for (let i = 0; i < payload.length; i++) {
                            let addr = payload[i].province + payload[i].city + payload[i].region + payload[i].detail + " " + payload[i].name + " " + payload[i].phone
                            addrs.push(addr);
                            addrNameToId[addr] = i;
                        }
                        this.addrs = addrs;
                        this.addrNameToId = addrNameToId;
                        this.addrLoading = false;
                        this.addrsDetail = payload;
                    } else {
                        Notify({type: "danger", message:'获取用户地址失败'});
                        this.addrLoading = false;
                        this.addrShow = false;
                    }
                } catch (err) {
                    console.log(err);
                    Notify({type: "danger", message:'获取用户地址失败'});
                }
            },
            selectProject(idx) {
                if (this.selectedProjectId == idx) {
                    this.selectedProjectId = -1;
                } else {
                    if (!this.projectList[idx].TotalToNumber || this.info.price > this.projectList[idx].TotalToNumber) {
                        Notify({type: "danger", message:'贡献值不足'});
                        return;
                    }
                    this.selectedProjectId = idx;
                }
            }
        },
        mounted() {
            // get commodity info from the url
            let id = this.$route.params.id;
            this.getCommodityInfo(id);
        }
    }
</script>

<style scoped>
    .item-image-box {
        position: relative;
        width: 100%;
    }
    .back-arrow {
        position: absolute;
        left: 20px;
        top: 30px;
        width: 20px;
        height: 20px;
    }

    .item-basic-info-box {
        position: relative;
        height: 110px;
    }

    .item-image {
        width: 100%;
        height: 368px;
    }

    .remain-num-box {
        padding: 20px 20px;
        color: white;
        font-size: 18px;
    }

    .price-box {
        position: absolute;
        right: 30px;
        bottom: -40px;
        height: 70px;
        width: 100px;
        background: linear-gradient(270deg,#81c4f5,#6149df);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 25px;
        color: white;
    }

    .item-name {
        color: white;
        padding: 0 20px;
        font-size: 30px;
        font-weight: bolder;
    }

    .exchange-box {
        position: relative;
        width: 100%;
        margin: 10px auto;
    }

    .exchange-btn {
        width: 85%;
        margin: 0 auto;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: linear-gradient(270deg,#81c4f5,#6149df);
        border-radius: 15px;
        color: white;
        font-size: 18px;
    }

    .item-description-box {
        overflow: scroll;
        height: calc(100vh - 368px - 90px - 120px - 70px);
        box-sizing: border-box;
        margin-top: 10px;
    }

    .description {
        padding: 0 20px;
    }

    .description-title {
        color: #6149df;
        line-height: 30px;
    }
    .description-item {
        color: white;
        line-height: 25px;
    }
    
    .exchange-detail-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .exchange-detail-box {
        background: #16171c;
        height: 600px;
        width: 80%;
        border-radius: 30px;
        padding: 30px 20px 20px 20px;
        box-sizing: border-box;
        overflow: scroll;
        position: relative;
    }

    .exchange-detail-banner {
        margin: 20px auto 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .exchange-detail-banner-image {
        width: 100%;
    }

    .exchange-detail-title {
        margin-top: 15px;
        font-size: 20px;
        font-weight: bold;
        color: white;
    }

    .exchange-price-box {
        display: flex;
        margin-top: 10px;
        align-items: flex-end;
    }

    .exchange-price {
        color: #6149df;
        font-size: 20px;
        font-weight: bold;
        width: 50%;
    }

    .exchange-remain {
        font-size: 13px;
        color: #747070;
        width: 50%;
        text-align: right;
    }

    .select-item-title {
        font-size: 12px;
        color: #fff;
        width: 30%;
        font-weight: bold;
    }
    
    .select-item {
        width: 100%;
        margin-top: 10px;
    }

    .select-item-val-box {
        margin: 12px auto;
        padding: 10px 20px;
        background-color: rgba(0,0,0,0.5);
        border-radius: 15px;
        border: 0.5px solid grey;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: left;
        cursor: pointer;
    }

    .select-item-val {
        width: 95%;
        font-size: 12px;
        color: #747070;
        text-align: left;
    }

    .icon-arrow {
        width: 15px;
        height: 15px;
    }

    .select-item-checkbox {
        margin: 12px auto;
        width: 100%;
    }

    .checkbox-item {
        display: flex;
        align-items: center;
        justify-content: left;
        margin: 10px auto;
    }

    .checkbox-item-name {
        width: 95%;
        font-size: 12px;
        color: #bfbfbf;
    }

    .icon-checkbox {
        width: 18px;
        height: 18px;
    }

    .submit-btn {
        background: linear-gradient(270deg,#81c4f5,#6149df);
        margin: 20px auto;
        width: 100%;
        border-radius: 20px;
        text-align: center;
        font-size: 13px;
        font-weight: bold;
        height: 50px;
        border-radius: 15px;
        line-height: 50px;
        color: white;
        cursor: pointer;
    }

    .success-hint-box {
        position: relative;
        background: linear-gradient(270deg,#81c4f5,#6149df);
        height: 150px;
        width: 80%;
        border-radius: 30px;
        padding: 30px 20px 20px 20px;
        box-sizing: border-box;
        overflow: scroll;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .success-icon {
        width: 40px
    }

    .success-hint-text {
        color: white;
        font-size: 22px;
        font-weight: bold;
        margin-top: 10px;
    }
    
    .close-icon {
        position: absolute;
        right: 15px;
        top: 10px;
        width: 30px;
    }
</style>